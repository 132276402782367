import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CampaignResultService } from './campaign-result.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    Campaign,
    GoToWorkNote,
    RecruitmentTypeEnum,
    StatusEnum,
} from 'app/shared/models/campaign.model';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ResultRequestConfirmDialogComponent } from './result-request-confirm-dialog/result-request-confirm-dialog.component';
import { Member } from '../../../shared/models/company.model';
import { get, isEmpty } from 'lodash';
import { ResultSortDialogComponent } from './result-sort-dialog/result-sort-dialog.component';
import { MatTabGroup } from '@angular/material/tabs';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { CAMPAIGN_RESULT_SORT_TYPE } from '../shared/campaign.constants';
import { C } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';
import { CampaignResultTableComponent } from './campaign-result-table/campaign-result-table.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-campaign-result',
    templateUrl: './campaign-result.component.html',
    styleUrls: ['./campaign-result.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS,
        },
    ],
})
export class CampaignResultComponent implements OnInit, OnDestroy {
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @ViewChild('resultTableComponent') resultTableComponent: CampaignResultTableComponent;

    @Input()
    members: Member[];
    @Input()
    campaign: Campaign;
    @Output()
    saveEmitter?: EventEmitter<any>;

    dialogRef: any;
    hasSelectedCandidates: boolean;
    searchInput: FormControl;
    deadlineControl: FormControl;
    goToWorkNoteControl: FormControl;

    canAccept = false;
    canSubmitResult = false;
    canSendResult = false;
    canViewNote = false;
    canDownloadCandidateData = false;
    hasOrder = false;
    canTakeAction = false;
    defaultTabIndex = 0;
    loadingIndicator = true;
    isProcessing = false;

    userId = null;
    ownerId = -1;
    reviewerId = -1;
    reviewerName = '';
    approverId = -1;
    approverName = '';

    goToWorkNote = '';
    workingPlace = '';
    acceptingDeadline: moment.Moment;
    minDate: moment.Moment;
    maxDate: moment.Moment;
    feedback = '';
    readonly = true;

    countAccept = 0;
    countOfficial = 0;
    countBackup = 0;
    sortStatus = '';
    translate = [];
    private unsubscribeAll: Subject<any>;

    constructor(
        private translateService: TranslateService,
        public router: Router,
        public campaignResultService: CampaignResultService,
        private matDialog: MatDialog,
        private matSnackBar: MatSnackBar
    ) {
        this.searchInput = new FormControl('');
        this.deadlineControl = new FormControl();
        this.goToWorkNoteControl = new FormControl();
        this.unsubscribeAll = new Subject();
        this.minDate = moment(new Date());
        this.maxDate = moment(new Date());
        this.saveEmitter = new EventEmitter();
    }

    ngOnInit(): void {
        const currentCampaign = this.campaignResultService.currentCampaign;

        this.feedback =
            this.campaignResultService.currentCampaign.resultFeedback;
        if (currentCampaign) {
            const acceptingDeadline = currentCampaign.jobInfo.acceptingDeadline;
            if (acceptingDeadline) {
                this.acceptingDeadline = moment(acceptingDeadline);
            } else {
                this.deadlineControl.markAsTouched();
            }
            this.maxDate = moment(currentCampaign.to);
            const customWorkingLocation =
                currentCampaign.jobInfo.customWorkingLocation;
            const goToWorkNote = currentCampaign.jobInfo.goToWorkNote;
            this.workingPlace =
                customWorkingLocation && customWorkingLocation.length
                    ? customWorkingLocation
                    : '';
            this.goToWorkNote =
                goToWorkNote && goToWorkNote.length ? goToWorkNote : '';
            this.initData();
        }
        this.campaignResultService.onSelectedCandidatesChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((selectedCandidates) => {
                this.hasSelectedCandidates = selectedCandidates.length > 0;
                this.initData();
            });
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this.unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe((searchText) => {
                this.campaignResultService.onSearchTextChanged.next(searchText);
            });

        this.campaignResultService.onCandidatesChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((selectedCandidates) => {
                this.initData();
            });

        this.setTab();
        this.sortStatus = this.campaign.userAction.resultSortStatus;

        this.campaignResultService.onLoadingChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((isLoading) => {
                this.loadingIndicator = isLoading;
            });

        this.translateService
            .get('CAMPAIGN_RESULT')
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((t) => {
                this.translate = t;
            });

        this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((t) => {
                const translation = get(t, 'translations["CAMPAIGN_RESULT"]') || {};
                if (!isEmpty(translation)) {
                    this.translate = translation;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.campaignResultService.candidates = [];
    }

    submitResult() {
        this.isProcessing = true;
        this.openSubmitResultPopup((data) => {
            this.campaignResultService.submitCampaignResult(data).then(
                () => {
                    this.matSnackBar.open(this.translate['SUBMIT_RESULT_SUCCESS'], 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                    this.approverId = parseInt(data.approverId, 10);
                    this.reviewerId = parseInt(data.reviewerId, 10);
                    this.resultTableComponent.maxScrollTop = 0;
                    this.isProcessing = false;
                },
                (err) => {
                    this.matSnackBar.open(
                        this.translate['SUBMIT_RESULT_FAILED'],
                        'OK',
                        {
                            verticalPosition: 'top',
                            duration: 2000,
                        }
                    );
                    this.isProcessing = false;
                    console.log('failed to send submit request', err);
                }
            );
        });
    }

    sendOtherSubmit() {
        //check either ship or approve
        if (
            this.campaignResultService.currentResultStatus ===
            StatusEnum.PENDING_REVIEW
        ) {
            this._shipResult();
        }

        if (
            this.campaignResultService.currentResultStatus ===
            StatusEnum.REVIEWED
        ) {
            this._approveResult();
        }
    }

    isAbleToTakeAction() {
        return this.campaignResultService.isManagingResult();
    }

    hasAtLeastOneResult() {
        return (
            this.campaignResultService.candidates.findIndex(
                (c) =>
                    !isEmpty(c.recruitmentType) &&
                    c.recruitmentType !== RecruitmentTypeEnum.NONE &&
                    c.recruitmentType !== RecruitmentTypeEnum.NOT_AVAILABLE
            ) > -1
        );
    }

    isAbleToAccept() {
        if (
            this.campaignResultService.currentResultStatus ===
            StatusEnum.REVIEWED
        ) {
            return this.userId === this.approverId;
        }

        if (
            this.campaignResultService.currentResultStatus ===
            StatusEnum.PENDING_REVIEW
        ) {
            return this.userId === this.reviewerId;
        }

        return false;
    }

    isAbleToSendResultToCandidate() {
        const approved =
            this.campaignResultService.currentResultStatus ===
            StatusEnum.APPROVED;
        return approved && this.campaignResultService.isInAdminGroup();
    }

    _isAbleToDownloadData() {
        return (
            this.campaignResultService.currentResultStatus ===
            StatusEnum.NOTIFIED
        );
    }

    _shipResult() {
        this.isProcessing = true;
        this.campaignResultService.shipCampaignResult().then(
            (response) => {
                this.matSnackBar.open(this.translate['SHIP_RESULT_SUCCESS'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this.isProcessing = false;
                this.resultTableComponent.maxScrollTop = 0;
                console.log('success to send ship request', response);
            },
            (err) => {
                this.matSnackBar.open(
                    this.translate['SHIP_RESULT_FAILED'],
                    'OK',
                    {
                        verticalPosition: 'top',
                        duration: 2000,
                    }
                );
                this.isProcessing = false;
                console.log('failed to send ship request', err);
            }
        );
    }

    _approveResult() {
        this.isProcessing = true;
        this.campaignResultService.approveCampaignResult().then(
            (response) => {
                this.matSnackBar.open(this.translate['APPROVE_RESULT_SUCCESS'], 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this.isProcessing = false;
                this.resultTableComponent.maxScrollTop = 0;
                console.log('success to send approve request', response);
            },
            (err) => {
                this.matSnackBar.open(
                    this.translate['SHIP_RESULT_FAILED'],
                    'OK',
                    {
                        verticalPosition: 'top',
                        duration: 2000,
                    }
                );
                this.isProcessing = false;
                console.log('failed to send approve request', err);
            }
        );
    }

    denyCampaignResult() {
        this.campaignResultService.rejectCampaignResult(this.feedback).then(
            (response) => {
                this.matSnackBar.open('Từ chối phê duyệt thành công!!!', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                console.log('sucessed to send reject request', response);
            },
            (err) => {
                this.matSnackBar.open(
                    'Gửi từ chối phê duyệt không thành công, vui lòng thử lại sau',
                    'OK',
                    {
                        verticalPosition: 'top',
                        duration: 2000,
                    }
                );
                console.log('failed to send reject request', err);
            }
        );
    }

    sendResultToCandidate() {
        if (!this.acceptingDeadline || isEmpty(this.goToWorkNote)) {
            this.matSnackBar.open('Vui lòng nhập đầy đủ thông tin', 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
            this.tabGroup.selectedIndex = 1;
            this.deadlineControl.markAsDirty();
            this.deadlineControl.markAllAsTouched();
            this.goToWorkNoteControl.markAsDirty();
            this.goToWorkNoteControl.markAllAsTouched();
            return;
        }

        const note: GoToWorkNote = {
            content: this.goToWorkNote,
            deadline: moment(this.acceptingDeadline).endOf('day'),
        };
        this.isProcessing = true;
        this.campaignResultService.notifyCampaignResult(note).then(
            (response) => {
                this.matSnackBar.open('Gửi thông báo thành công!!!', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000,
                });
                this.campaignResultService.resetCampaignResult();
                this.isProcessing = false;
                console.log('succeed to send notify request', response);
            },
            (response) => {
                this.isProcessing = false;
                const { error } = response;
                const message = get(error, 'message') || '';

                if (message === 'not_enough_invitation') {
                    const { requested = 0, available = 0 } = error;
                    this.handleNotEnoughInvitation(requested, available);
                    return;
                }

                if (message === 'invitation_expired') {
                    this.handleExpiredInvitation();
                    return;
                }

                this.matSnackBar.open(
                    'Gửi thông báo không thành công, vui lòng thử lại sau.',
                    'OK',
                    {
                        verticalPosition: 'top',
                        duration: 2000,
                    }
                );
                console.log('failed to send notify request', response);
            }
        );
    }

    handleNotEnoughInvitation(requested = 0, available = 0) {
        const confirmDialog = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        confirmDialog.componentInstance.confirmMessage = `Tài khoản hiện còn ${available} không đủ để tuyển ${requested} ứng viên`;
        confirmDialog.componentInstance.yesLabel = 'Kiểm tra tài khoản';
        confirmDialog.componentInstance.noLabel = 'Đóng';

        confirmDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            window.open('/account/activation', '_self');
        });
    }

    handleExpiredInvitation() {
        const confirmDialog = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        confirmDialog.componentInstance.confirmMessage = `Gói tuyển dụng đã hết hạn`;
        confirmDialog.componentInstance.yesLabel = 'Kiểm tra tài khoản';
        confirmDialog.componentInstance.noLabel = 'Đóng';

        confirmDialog.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            window.open('/account/activation', '_self');
        });
    }

    openSortResult() {
        const getSortStatusFromCriteria = (criteria) => {
            let result = 'Xếp hạng:';
            const sortFields = [];
            const filteredCriteria = criteria.filter((c) => !isEmpty(c.path));
            if (isEmpty(filteredCriteria)) {
                return {
                    sortStatus: '',
                    sortFields,
                };
            }

            filteredCriteria.forEach(
                ({ name = '', path = '', isAsc = false, field }, index) => {
                    if (!isEmpty(path)) {
                        result += ` ${index === 0 ? '' : '> '}${name}`;
                        sortFields.push(field);
                    }
                }
            );

            return {
                sortStatus: result,
                sortFields,
            };
        };

        this.openSortResultPopup(async (criteria) => {
            this.campaignResultService.sortCandidates(criteria, () => {
                this.reloadResultTable();
            });

            // Save sort status text
            const { sortStatus, sortFields } =
                getSortStatusFromCriteria(criteria);
            this.sortStatus = sortStatus;
            this.saveEmitter.emit({
                isSavingSortStatus: true,
                sortStatus: this.sortStatus,
                sortFields,
            });
        });
    }

    getTranslatedAcceptingDeadline() {
        return this.acceptingDeadline
            ? moment(this.acceptingDeadline).format('DD-MM-YYYY')
            : 'Chưa chọn';
    }

    openDenyPopup() {
        const denyPopup = this.matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        denyPopup.componentInstance.confirmMessage =
            'Bạn có thực sự muốn từ chối và gửi nhận xét?';
        denyPopup.componentInstance.inputLabel = 'Nhận xét';
        denyPopup.componentInstance.yesLabel = 'Xác nhận';

        denyPopup.afterClosed().subscribe(({ result, input }) => {
            if (result) {
                this.feedback = input;
                this.denyCampaignResult();
            }
        });
    }

    getTableStyle() {
        const resultStatus =
            get(this.campaignResultService.currentCampaign, 'resultStatus') ||
            StatusEnum.NOT_READY;

        if (
            resultStatus === StatusEnum.NOT_READY ||
            resultStatus === StatusEnum.REJECTED
        ) {
            return {
                height: 'calc(100vh - 35rem)',
                width: '100%',
            };
        }

        if (
            resultStatus === StatusEnum.PENDING_REVIEW ||
            resultStatus === StatusEnum.REVIEWED
        ) {
            return {
                height: 'calc(100vh - 37rem)',
                width: '100%',
            };
        }

        return {
            height: 'calc(100vh - 32rem)',
            width: '100%',
        };
    }

    reloadResultTable() {
        this.campaignResultService
            .getCampaignResults(this.campaign.id)
            .then(() => {});
    }

    private initData() {
        this.hasOrder = this.campaignResultService.checkHasOrder();
        this.canAccept = this.isAbleToAccept();
        this.canTakeAction = this.isAbleToTakeAction();
        this.canSubmitResult =
            this.isAbleToTakeAction() && this.hasAtLeastOneResult();
        this.canSendResult = this.isAbleToSendResultToCandidate();
        this.canDownloadCandidateData = this._isAbleToDownloadData();
        this.canViewNote = this.isAbleToViewNote();
        this.readonly = !this.canSendResult;
        this.userId = this.campaignResultService.user.identity;

        if (!this.canSendResult) {
            this.goToWorkNoteControl.disable();
        } else {
            this.goToWorkNoteControl.enable();
        }

        const currentCampaign = this.campaignResultService.currentCampaign;
        const {
            ownerId,
            approverId,
            reviewerId,
            approverName = '',
            reviewerName = '',
        } = currentCampaign;
        this.ownerId = ownerId;
        this.approverId = approverId;
        this.reviewerId = reviewerId;
        this.approverName = approverName;
        this.reviewerName = reviewerName;

        this.resetStatistic();
        this.campaignResultService.onStatisticChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((result) => {
                if (!isEmpty(result)) {
                    this.resetStatistic();
                }
            });
    }

    private resetStatistic() {
        const { countAccept, countOfficial, countBackup } =
            this.campaignResultService.getCounts();
        this.countAccept = countAccept;
        this.countOfficial = countOfficial;
        this.countBackup = countBackup;
    }

    private setTab() {
        const currentCampaign = this.campaignResultService.currentCampaign;
        const resultStatus =
            get(currentCampaign, 'resultStatus') || StatusEnum.NOT_READY;
        if (resultStatus === StatusEnum.APPROVED) {
            this.defaultTabIndex = 1;
            if (this.tabGroup) {
                this.tabGroup.selectedIndex = 1;
            }
        }
    }

    private openSortResultPopup(callback = (data) => {}) {
        const popup = this.matDialog.open(ResultSortDialogComponent, {});

        popup.componentInstance.conditionList = this.campaign.rounds.map(
            (r, index) => ({
                name: `${index + 1} ${this.translateRoundName(r.type)}`,
                path: `roundResults[${index}].score`,
                isAsc: false,
                type: CAMPAIGN_RESULT_SORT_TYPE.ROUND_SCORE,
                typeIndex: index,
                field: `round_${index}`,
            })
        );
        popup.componentInstance.conditionList.push({
            name: `Tuổi`,
            path: 'candidate.age',
            isAsc: false,
            type: CAMPAIGN_RESULT_SORT_TYPE.AGE,
            field: 'age',
        });
        popup.componentInstance.conditionList.push({
            name: `Điểm trung bình`,
            path: 'average',
            isAsc: false,
            type: CAMPAIGN_RESULT_SORT_TYPE.AVERAGE,
            field: 'average',
        });
        popup.componentInstance.conditionList.push({
            name: `Sức khỏe`,
            path: 'candidate.healthCertificate.type',
            isAsc: false,
            type: CAMPAIGN_RESULT_SORT_TYPE.HEALTH,
            field: 'health',
        });
        popup.componentInstance.conditionList.push({
            name: `Kinh nghiệm`,
            path: 'candidate.totalExperienceMonth',
            isAsc: false,
            type: CAMPAIGN_RESULT_SORT_TYPE.EXPERIENCE,
            field: 'exp',
        });

        popup.afterClosed().subscribe((e) => {
            if (!isEmpty(e)) {
                callback(e);
            }
        });
    }

    private openSubmitResultPopup(callback = (data) => {}) {
        const popup = this.matDialog.open(
            ResultRequestConfirmDialogComponent,
            {}
        );

        popup.componentInstance.members = this.members;
        popup.componentInstance.reviewerId = this.reviewerId + '';
        popup.componentInstance.approverId = this.approverId + '';
        popup.componentInstance.acceptingDeadline =
            this.getTranslatedAcceptingDeadline();
        popup.componentInstance.countOfficial = this.countOfficial;
        popup.componentInstance.countBackup = this.countBackup;

        popup.afterClosed().subscribe((e) => {
            if (!isEmpty(e)) {
                callback({
                    acceptingDeadline: this.acceptingDeadline,
                    ...e,
                });
            }
        });
    }

    private isAbleToViewNote() {
        return (
            this.campaignResultService.currentResultStatus ===
                StatusEnum.APPROVED ||
            this.campaignResultService.currentResultStatus ===
                StatusEnum.NOTIFIED
        );
    }

    private translateRoundName(type: string): string {
        if (type === 'INTERVIEW') {
            return this.translate['INTERVIEW'];
        }
        return this.translate['TEST'];
    }
}
