<div
    id="candidate-results"
    class="page-layout simple left-sidebar inner-sidebar inner-scroll"
>
    <mat-spinner
        color='accent'
        *ngIf="!loadingIndicator && !this.campaignResultService.fetchedAll && this.campaignResultService.isFetching"
        style="margin: auto; position: absolute; left: 40%; top: 40%; z-index: 999"
    ></mat-spinner>
    <mat-spinner
        color='accent'
        *ngIf="isProcessing"
        style="margin: auto; position: absolute; left: 40%; top: 40%; z-index: 999"
    ></mat-spinner>
    <div class="center">
        <mat-tab-group #tabGroup [(selectedIndex)]="defaultTabIndex">
            <mat-tab label="{{ 'CAMPAIGN.ROUND.RESULT' | translate }}">
                <div fxLayout="column" class="mt-10 mb-10">
                    <div
                        class="multi-actions"
                        fxLayout="row"
                        fxLayoutAlign="start end"
                    >
                        <div fxLayoutAlign="start start">
                            <button
                                (click)="openSortResult()"
                                class="mr-8"
                                mat-raised-button
                                color="accent"
                                [disabled]="loadingIndicator"
                                *ngIf="canTakeAction"
                            >
                                <span class="text-uppercase">{{
                                    'CAMPAIGN.ROUND.RANK' | translate
                                }}</span>
                            </button>
                        </div>
                        <div fxFlex="auto">
                            <fw-campaign-result-selected-bar
                                *ngIf="canTakeAction"
                            >
                            </fw-campaign-result-selected-bar>
                        </div>
                    </div>
                    <div fxLayout="row" class="mt-8 pb-8 status-border-bottom">
                        <div fxFlex="40">
                            {{
                                'CAMPAIGN.ROUND.ACCEPTING_DEADLINE' | translate
                            }}:
                            {{ getTranslatedAcceptingDeadline() }}
                        </div>
                        <div fxFlex="20">
                            {{ 'CAMPAIGN.ROUND.ACCEPTED_COUNT' | translate }}:
                            {{ countAccept }}
                        </div>
                        <div fxFlex="20">
                            {{ 'CAMPAIGN.ROUND.OFFICIAL_COUNT' | translate }}:
                            {{ countOfficial }}
                        </div>
                        <div fxFlex="20">
                            {{ 'CAMPAIGN.ROUND.BACKUP_COUNT' | translate }}:
                            {{ countBackup }}
                        </div>
                    </div>
                </div>

                <app-campaign-result-table
                    #resultTableComponent
                    [tableStyle]="getTableStyle()"
                    [loadingIndicator]="loadingIndicator"
                    (onRefresh)='reloadResultTable()'
                ></app-campaign-result-table>

                <div
                    fxLayout="row"
                    fxFlex="auto"
                    fxLayoutAlign="end start"
                    class="mt-10"
                >
                    <div fxFlex="auto" class="sort-status-container">
                        <p *ngIf="!defaultTabIndex" class="sort-status-content">
                            {{ sortStatus }}
                        </p>
                    </div>

                    <button
                        *ngIf="canSubmitResult"
                        (click)="submitResult()"
                        mat-raised-button
                        color="accent"
                    >
                        <span class="text-uppercase">{{
                            'CAMPAIGN.ROUND.SEND_RESULT' | translate
                        }}</span>
                    </button>

                    <button
                        class="p-8 ml-4 mt-8"
                        *ngIf="canAccept"
                        (click)="openDenyPopup()"
                        mat-raised-button
                        fxLayoutAlign="start center"
                        color="warn"
                    >
                        <span class="text-uppercase">{{
                            'CAMPAIGN.ROUND.NOT_APPROVE' | translate
                        }}</span>
                    </button>

                    <button
                        class="p-8 ml-4 mt-8"
                        *ngIf="canAccept"
                        (click)="sendOtherSubmit()"
                        mat-raised-button
                        fxLayoutAlign="start center"
                        color="accent"
                    >
                        <span class="text-uppercase">{{
                            'CAMPAIGN.ROUND.APPROVE' | translate
                        }}</span>
                    </button>
                </div>
            </mat-tab>

            <mat-tab label="{{ 'CAMPAIGN.ROUND.ANNOUNCEMENT' | translate }}">
                <div
                    fxLayout="column"
                    class="mt-16"
                    fxLayoutGap="8px"
                    style="width: 70%; margin: auto"
                >
                    <mat-form-field (click)='picker1.open()' appearance="outline" fxFlex="100">
                        <mat-label>{{
                            'CAMPAIGN.ROUND.ACCEPTING_DEADLINE' | translate
                        }}</mat-label>
                        <input
                            [min]="minDate"
                            [max]="maxDate"
                            [matDatepicker]="picker1"
                            matInput
                            [(ngModel)]="acceptingDeadline"
                            name="deadline"
                            [disabled]="!canSendResult"
                            [formControl]="deadlineControl"
                            readonly="true"
                            [required]="canSendResult"
                        />
                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{
                            'CAMPAIGN.ROUND.WORKPLACE' | translate
                        }}</mat-label>
                        <input
                            matInput
                            disabled="true"
                            class="mat-input"
                            type="text"
                            name="workingPlace"
                            placeholder="{{
                                'CAMPAIGN.ROUND.WORKPLACE' | translate
                            }}"
                            [(ngModel)]="workingPlace"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>{{
                            'CAMPAIGN.ROUND.GO_TO_WORK_NOTE' | translate
                        }}</mat-label>
                        <textarea
                            matInput
                            class="mat-input"
                            type="text"
                            name="goToWorkNote"
                            [rows]="5"
                            placeholder=""
                            [(ngModel)]="goToWorkNote"
                            [formControl]="goToWorkNoteControl"
                            required
                        ></textarea>
                    </mat-form-field>
                    <div fxLayout="row">
                        <table style="width: 100%">
                            <tr>
                                <td style="width: 150px">
                                    {{
                                        'CAMPAIGN.ROUND.OFFICIAL_COUNT'
                                            | translate
                                    }}:
                                </td>
                                <td>{{ countOfficial }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        'CAMPAIGN.ROUND.BACKUP_COUNT'
                                            | translate
                                    }}:
                                </td>
                                <td>{{ countBackup }}</td>
                            </tr>
                            <tr *ngIf="reviewerName">
                                <td>
                                    {{ 'CAMPAIGN.ROUND.REVIEWER' | translate }}:
                                </td>
                                <td>{{ reviewerName }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'CAMPAIGN.ROUND.APPROVER' | translate }}:
                                </td>
                                <td>{{ approverName }}</td>
                            </tr>
                        </table>
                    </div>

                    <div
                        fxLayout="row"
                        fxFlex="auto"
                        fxLayoutAlign="end start"
                        class="mt-10"
                    >
                        <button
                            class="p-8 ml-4"
                            *ngIf="canSendResult"
                            (click)="sendResultToCandidate()"
                            mat-raised-button
                            fxLayoutAlign="start center"
                            color="accent"
                            [disabled]="isProcessing"
                        >
                            <span class="text-uppercase">{{
                                'CAMPAIGN.ROUND.ANNOUNCEMENT' | translate
                            }}</span>
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
